// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-buyers-js": () => import("./../../../src/pages/buyers.js" /* webpackChunkName: "component---src-pages-buyers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-detail-js": () => import("./../../../src/pages/property-detail.js" /* webpackChunkName: "component---src-pages-property-detail-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-sellers-js": () => import("./../../../src/pages/sellers.js" /* webpackChunkName: "component---src-pages-sellers-js" */)
}

