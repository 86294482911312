const initialState = {
  activeMenu: 'home'
};

const UPDATE_MENU = 'UPDATE_MENU';


export const updateMenu = menuId => ({
  type: UPDATE_MENU, menuId
});

export default (state = initialState, action) => {
  switch (action.type) {
      case UPDATE_MENU:
      console.log('reached menu', action)
      return { ...state, activeMenu: action.menuId };
    default:
      return state;
  }
};